import React from "react";

import PackageItem from "../package-item";


const PackageList = ({ packages, currentId = false, standId }) => {

    let currentFound = false;

    return(
        <div className="package-list">
            { packages.map(
                (pkg) => {

                    let currentPackage = false;
                    let possiblePackage = currentFound;

                    if(currentId && pkg.id === currentId){
                        currentPackage = true;
                        possiblePackage = false;
                        currentFound = true;
                    }

                    return(
                        <PackageItem
                            key={ pkg.id }
                            { ...pkg }
                            possiblePackage={ possiblePackage }
                            currentPackage={ currentPackage }
                            standId={ standId }
                        />
                    )
                })
            }
        </div>
    );
};

export default PackageList;