import React from "react";


import Icons from "components/icons";


const ShowroomDownloadsRow = ({ attachment, control }) => {

    return(
        <div className="attachment-row">
            <div className="attachment-file">
                <div className="icon-wrap">
                    <Icons.File />
                </div>
                <div className="file-wrap">
                    <p className="name">{ attachment.original_name }</p>
                    <p className="size">{ attachment.size }</p>
                </div>
            </div>
            <div className="attachment-description">
                { attachment.description }
            </div>
            <div className="attachment-action">
                { control ?
                    <>
                        <div>EDIT</div>
                        <div>DELETE</div>
                    </> :
                    <a href={ attachment.path } target="_blank" rel="noreferrer">
                        <Icons.Download/> DOWNLOAD
                    </a>
                }
            </div>
        </div>
    );
};

export default ShowroomDownloadsRow;