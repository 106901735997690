import React from "react";

import { CardCompany, CardProduct } from "components/card";
import { SwiperService } from "components/service";

import "./search-results.scss";


const SearchResults = ({ stands, products, paginatorInfo }) => {

    const { Slider, Slide } = SwiperService;

    if(paginatorInfo.total === null || !paginatorInfo.total){
        return null;
    }

    return(
        <div className={`search-results ${ products ? "product" : "company" }`}>
            <Slider
                className="search-results-slider"
                settings={{
                    spaceBetween: 0,
                    loop: false,
                    //nested: true,
                    roundLengths: true,
                    initialSlide: Math.ceil(paginatorInfo.count / 2) - 1,
                    centerInsufficientSlides: false,
                    centeredSlides: true,
                    keyboard: {
                        enabled: true
                    }
                }}
            >
            { stands && stands.map( (stand) => {
                return (
                    <Slide key={ stand.id }>
                        { ( { isActive } ) =>{

                            return(
                                <CardCompany.Flip
                                    stand={ stand }
                                    isActive={ isActive }
                                />
                            )
                        }}
                    </Slide>
                )
            })}
            { products && products.map( product => {
                return (
                    <Slide key={ product.id }>
                        { ({isActive}) =>
                            <CardProduct.Flip
                                flipped ={ isActive }
                                product={ product }
                            />
                        }
                    </Slide>
                )
            })}
            </Slider>
        </div>
    );
};

export default SearchResults;