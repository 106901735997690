import React from "react";
import { Link } from "react-router-dom";

import { useExhibitionEvent, useLogout} from "components/use-hooks";
import { metaFields } from "utils";

import "./showroom-popover-menu.scss";



const UserPopoverMenu = ({ navClass="" }) => {

    const { logout } = useLogout();

    const ee = useExhibitionEvent();

    const { getValue, normalize } = metaFields,
        meta = normalize(ee.meta_fields);

    const legalFiles = {
        terms : getValue(meta, "ee_terms_of_service", false),
        privacy : getValue(meta, "ee_privacy_policy", false),
    };

    return(
        <nav className={ `popover-menu ${navClass}` }>
            <ul>
                <li>
                    <Link to={"/profile/"}>Profile settings</Link>
                </li>
                { legalFiles.terms &&
                    <li>
                        <a href={ legalFiles.terms } target="_blank" rel="noreferrer">Terms of Service</a>
                    </li>
                }
                { legalFiles.privacy &&
                    <li>
                        <a href={ legalFiles.privacy } target="_blank" rel="noreferrer">Privacy Policy</a>
                    </li>
                }
                <li>
                    <button onClick={ logout }>Logout</button>
                </li>
            </ul>
        </nav>
    );
};

export default UserPopoverMenu;