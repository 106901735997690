import React from "react";
import { Link } from "react-router-dom";
import {useVarParam} from "../../use-hooks";


const ShowroomPopoverMenu = ({ navClass="", standId }) => {

    return(
        <nav className={ `popover-menu ${navClass}` }>
            <ul>
                { useVarParam().get("productsOn") &&
                    <li>
                        <Link to={"/showroom-configurator/product"}>Design product page</Link>
                    </li>
                }
                <li>
                    <Link to={"/showroom-configurator/company"}>Design company page</Link>
                </li>
                { useVarParam().get("communicationOn") &&
                    <li>
                        <Link to={"/showroom-configurator/communication"}>Design communication page</Link>
                    </li>
                }
                { useVarParam().get("downloadOn") &&
                    <li>
                        <Link to={"/showroom-configurator/downloads"}>Design downloads page</Link>
                    </li>
                }
                <li>
                    <Link to={"/showroom-configurator/profile"}>Edit company profile</Link>
                </li>
                <li>
                    <Link to={"/showroom-configurator/team"}>Manage team</Link>
                </li>
                <li>
                    <Link to={"/showroom-configurator/analytics"}>Showroom analytics</Link>
                </li>
                <li>
                    <Link to={`/showroom-${standId}/`}>Showroom preview</Link>
                </li>
            </ul>
        </nav>
    );
};

export default ShowroomPopoverMenu;