import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, Checkbox, Modal } from "antd";

import { USER_REGISTRATION } from "graphql/mutation/user-gql";
import { authParamsVar } from "graphql/cache";

import LanguageSelector from "components/languages";
import { errorNotification } from "components/request-result";
import { useExhibitionEvent } from "components/use-hooks";
import { metaFields } from "utils";
import Icons from "components/icons";

import './registration-form.scss';


const formSubmit = ({ values, registrationRequest }) => {

    registrationRequest({
        variables: {
            input: {
                name: values.name,
                surname: values.surname,
                email: values.email,
                password: values.password,
                locale: authParamsVar().interfaceLanguage,
                ee_id: authParamsVar().eeId
            }
        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const SuccessRegistration = ({ form }) => {

    authParamsVar({ ...authParamsVar(), legacyBlock: true });

    return (
        <div className="registration-form-message">
            <h1>
                Registration successful<br />
                Check your email
            </h1>
            <p>We’ve sent a message to <b>{ form.getFieldValue('email') }</b> with a link to activate your account. </p>
            <div className="instructions">
                <h3>Didn’t get an email?</h3>
                <p>If you don’t see an email from us within a few minutes, a few things could have happened:</p>
                <ul className="dot-list">
                    <li>The email is in your spam folder</li>
                    <li>You accidentally gave us another email address</li>
                    <li>The email address you entered had a mistake or typo</li>
                </ul>
            </div>
            <Link to="/" className="route-link">To the sign in page</Link>
        </div>
    );
};

const RegistrationForm = () => {

    const ee = useExhibitionEvent();

    const [ showModal, setShowModal ] = useState(false);

    const { getValue, normalize } = metaFields,
        meta = normalize(ee.meta_fields);

    const [ form ] = Form.useForm(),
        [ registrationRequest, { loading } ] = useMutation(USER_REGISTRATION,
            {
                update() {
                    authParamsVar({
                        ...authParamsVar(),
                        sendConfirmationEmail: true
                    });
                }
            }
        );

    authParamsVar({
        ...authParamsVar(),
        legacyBlock: false
    });

    const legalFiles = {
        terms: getValue(meta, "ee_terms_of_service", false),
        privacy: getValue(meta, "ee_privacy_policy", false)
    };

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <div className="form-holder registration">
            { authParamsVar().sendConfirmationEmail ?
                <SuccessRegistration form={ form } /> :
                <>
                    <h1>
                        Registration to <br />
                        { ee.title }
                    </h1>
                    <Form
                        className="registration-form"
                        form={ form }
                        onFinish={ (values) => {
                            formSubmit({ values, registrationRequest });
                        } }
                    >
                        <Form.Item
                            label={ <span>Name</span> }
                            name="name"
                            { ...formItemLayout }
                            rules={ [
                                {
                                    required: true,
                                    message: 'Please input your Name'
                                }
                            ] }
                        >
                            <Input name="user-name" />
                        </Form.Item>
                        <Form.Item
                            label={ <span>Surname</span> }
                            name="surname"
                            { ...formItemLayout }
                            rules={ [
                                {
                                    required: true,
                                    message: 'Please input your Surname'
                                }
                            ] }
                        >
                            <Input name="user-surname" />
                        </Form.Item>
                        <Form.Item
                            label={ <span>E-Mail</span> }
                            { ...formItemLayout }
                            name="email"
                            rules={ [
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail',
                                },
                                {
                                    required: true,
                                    message: 'Please input your Email'
                                }
                            ] }
                        >
                            <Input name="login-email" />
                        </Form.Item>
                        <Form.Item
                            label={ <span>Password</span> }
                            { ...formItemLayout }
                            name="password"
                            rules={ [
                                { min: 8, message: 'Password must be at least 8 characters' },
                                { required: true, message: 'Please input your Password' }
                            ] }
                        >
                            <Input.Password type="password" name="password" />
                        </Form.Item>
                        { (legalFiles.terms || legalFiles.privacy) &&
                            <>
                                <Form.Item
                                    className="legal-block"
                                    name="agreement"
                                    valuePropName="checked"
                                    rules={ [
                                        {
                                            validator: (_, value) => {

                                                if (value)
                                                {
                                                    return Promise.resolve();
                                                } else
                                                {
                                                    setShowModal(true);
                                                    return Promise.reject(new Error('You should to agree for the registration '))
                                                }
                                            },

                                        },
                                    ] }
                                >
                                    <Checkbox>
                                        I agree with the
                                        { legalFiles.terms &&
                                            <a href={ legalFiles.terms } target="_blank" rel="noreferrer"> Terms of Service </a>
                                        }
                                        { legalFiles.terms && legalFiles.privacy &&
                                            <>and</>
                                        }
                                        { legalFiles.privacy &&
                                            <a href={ legalFiles.privacy } target="_blank" rel="noreferrer"> Privacy Policy</a>
                                        }
                                    </Checkbox>
                                </Form.Item>

                                <p style={ {
                                    marginBottom: '10px',
                                    fontSize: '11px',
                                    color: '#888888'
                                } }>
                                    Mir ist bewusst, dass beim Betreten der Plattform meine, im Rahmen der Registrierung erhobenen Daten (Name, E-Mail und Firmenname), an den Aussteller im Expobereich übergeben werden.
                                    Eine Pauschale Weitergabe meiner Daten an alle Aussteller der Plattform erfolgt nicht.

                                </p>
                            </>
                        }
                        <Form.Item>
                            <div className="form-actions">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={ loading }
                                >
                                    Register
                                </Button>
                            </div>
                        </Form.Item>
                        <div className="language-wrap">
                            <LanguageSelector />
                        </div>
                        <Modal
                            className="standard-request-modal"
                            visible={ showModal }
                            closeIcon={ <Icons.Cross /> }
                            width={ 400 }
                            destroyOnClose={ true }
                            centered
                            footer={ null }
                            onCancel={ () => setShowModal(false) }
                        >
                            <div>
                                Please confirm the
                                { legalFiles.terms &&
                                    <> Terms of Service </>
                                }
                                { legalFiles.terms && legalFiles.privacy &&
                                    <> and </>
                                }
                                { legalFiles.privacy &&
                                    <> Privacy Policy </>
                                }
                                to be able to register
                            </div>
                            <div className="modal-action">
                                <Button
                                    type="primary"
                                    onClick={ () => setShowModal(false) }
                                >
                                    OK
                                </Button>
                            </div>
                        </Modal>
                    </Form>
                    <div className="sign-link-wrap">
                        <span>Already registered?</span>
                        <Link to="/">Sign in</Link>
                    </div>
                </>
            }
        </div>
    );
};


export default RegistrationForm;