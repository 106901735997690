import React from "react";

import { MenuPopover } from "components/service";
import { AvatarBlock } from "components/avatar";
import { ShowroomPopoverMenu } from "components/menus";


const ShowroomMenuPopover = ({ user, children }) => {

    const {
        stand_permission,
        stand
    } = user;

    if(!user.stand_permission || !stand_permission || (stand_permission && stand[0].status === "draft")){
        return (React.cloneElement( children, { user }));
    }

    if(!stand_permission){
        return null
    }

    return (
        <div className="showroom-menu-popover-wrap">
            <MenuPopover
                title="Company showroom"
                image={ stand[0].logo }
                imageShape={ "square" }
            >
                <AvatarBlock
                    image={ stand[0].logo }
                    size={ 60 }
                    shape={ "square" }
                >
                    <h5>{ stand[0].title }</h5>
                </AvatarBlock>
                <ShowroomPopoverMenu standId={  stand[0].id } />
            </MenuPopover>
        </div>
    );
};

export default ShowroomMenuPopover;