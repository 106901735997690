import { useQuery } from "@apollo/client";

import { GET_EE_BY_URL } from "graphql/query/exhibition-event-gql";


const useExhibitionEvent = (fetchPolicy = "cache-only", withStatus = false ) => {

    const { data : { eeLocateByUrl } = { }, loading, error } = useQuery( GET_EE_BY_URL, {
        variables: {
            uri: window.location.hostname === "localhost" ? "mmd.fairgalaxy.de" : window.location.hostname
            //uri: window.location.hostname === "localhost" ? "vep.mp-dev.eu" : window.location.hostname
        },
        fetchPolicy
    });

    if(withStatus){
        return { eeLocateByUrl, loading, error };
    }


    if(eeLocateByUrl){
        return eeLocateByUrl;
    }

    return false;
}

export default useExhibitionEvent;