import React from "react";


const ShowroomUploadAttachment = ({ standId }) => {

    return (
        <>UPLOAD ATTACHMENT</>
    )
}


export default ShowroomUploadAttachment;