import React from "react";
import { Collapse } from "antd";

import ProductCreateButton from "../product-create-button";
import Icons from "components/icons";

import "./product-create-block.scss";


const ProductCreateBlock = ({ standId, createTypes = [ "product" ] }) => {

    const { Panel } = Collapse;

    return(
        <Collapse
            className="product-create-block"
            expandIconPosition="right"
            expandIcon={ () => <Icons.Arrow  /> }
            defaultActiveKey={['1']}
            ghost
        >
            <Panel
                key="1"
                header={
                    <div className="header-content">
                        <h4>Add new product </h4>
                        <p><span>5 products</span> remain in your package</p>
                    </div>
                }
            >
                <div className="product-create-button-wrap">
                { createTypes.map(
                    type =>
                        <ProductCreateButton
                            type={type}
                            key={type}
                            standId={ standId }
                        />
                    )
                }
                </div>
            </Panel>
        </Collapse>
    );
};

export default ProductCreateBlock;