import React from "react";
import { useQuery } from "@apollo/client";

import { GET_PRODUCT } from "graphql/query/product-gql";

import { ProductRoutes } from "components/routes";
import { Suspense } from "components/request-result";
import { useVarParam } from "components/use-hooks";

import "./product-page.scss";


const ProductPage = ({ match }) => {

    const varParam = useVarParam();

    const { productName } = match.params;

    const [ productId ] = productName.split("-");

    const { loading, error, data } = useQuery( GET_PRODUCT, {
        variables: {
            id: productId
        }
    });

    varParam.set({
        appBlockClass: ["bg-base"],
        mainBlockClass: [""],
        headerOn: false
    });

    return(
        <div className="product-page">
            <Suspense state={ { loading, error, data} }>
                <ProductRoutes />
            </Suspense>
        </div>
    );

};

export default ProductPage;