import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { LOG_VISITORS } from "graphql/query/analytics-gql";

import AnalyticsTable from "../analytics-table";
import AnalyticsExportButton from "../analytics-export";
import { UserAvatar } from "components/user";
import Icons from "components/icons";
import { useVarParam } from "components/use-hooks";



const prepareTableData = (data, productsOn = false, pathBase) => {

    return data.map( (row) => {

        let extraCol = {};

        if(productsOn){
            extraCol = {
                viewsProduct : {
                    value : row.view_products > 0 ? row.view_products : "–",
                    title : <><Icons.Eye type="product" className="product-views" /> Product Views</>
                },
                favoriteProduct : {
                    value : row.favorite_products > 0 ? row.favorite_products : "–",
                    title : <><Icons.Favorite type="product"  className="product-favorites" /> Product Favorites</>
                },
                more: {
                    value : <Link to={ `${pathBase}visitor/${ row.user.id }/`} className="analytics-more"><Icons.Arrow type="circle"  /></Link>,
                    title : ""
                }
            }
        }

        return {
            key: row.user.id,
            user : {
                value : <UserAvatar user={ row.user } size={ 62 } companyView="short"  />,
                title : <><Icons.Avatar type="simple" /> Visitor</>
            },
            views : {
                value : row.view_stands > 0 ? row.view_stands : "–",
                title : <><Icons.Eye className="showroom-views" /> Showroom views</>
            },
            favorite : {
                value : row.favorite_stands ? <Icons.Check className="check-icon" /> : "–",
                title : <><Icons.Favorite className="showroom-favorites" /> Showroom favorites</>
            },
            ...extraCol
        };
    });

}

const AnalyticsVisitorsDetailedBlock = ({ standId, currentPage = 1, pathBase }) => {

    const history = useHistory();

    const { data : { logVisitors } = {} , loading } = useQuery( LOG_VISITORS, {
        variables: {
            standId,
            page: currentPage,
            orderBy: [{
                column: 'VIEW_STANDS',
                order: 'DESC'
            }]
        },
        fetchPolicy: "cache-and-network"
    });

    const productsOn = useVarParam().get("productsOn");

    const tableData = logVisitors ? prepareTableData( logVisitors.data, productsOn, pathBase ) : [];
    const { paginatorInfo } = logVisitors ?? {};

    return(
        <div className="analytics-block">

            <h2>Detailed visitors analytics</h2>
            { tableData &&
                <>
                    <div className="analytics-export-wrap">
                        <AnalyticsExportButton model={ "StandUsers" } />
                    </div>
                    <AnalyticsTable
                        tableData={ tableData }
                        loading={ loading }
                        pagination={{
                            pageSize: paginatorInfo?.perPage,
                            total: paginatorInfo?.total,
                            hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                            current: currentPage,
                            onChange: ( page ) => {

                                let path = page === 1 ? `${ pathBase }visitors/` : `${ pathBase }visitors/${ page }`

                                history.push( path );
                            }
                        }}
                    />
                </>
            }
        </div>
    );
};

export default AnalyticsVisitorsDetailedBlock;