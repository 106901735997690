import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal } from "antd";

import { PRODUCT_DELETE } from "graphql/mutation/product-gql";
import { GET_PRODUCTS_BY_STAND } from "graphql/query/product-gql";

import { errorNotification, successNotification } from "components/request-result";
import Icons from "components/icons";



const productDeleteAction = ({
        product,
        removeProduct,
        toggleConfirmModal,
        action
    }) => {

    removeProduct({
        variables: {
            productId: parseInt(product.id)
        },
        update : (cache, { data }) => {

            const {
                productDelete: {
                    label,
                    message
                }
            } = data;

            setTimeout(
                () => {

                    const { standProducts } = cache.readQuery({
                        query: GET_PRODUCTS_BY_STAND,
                        variables: {
                            id: product.stand.id
                        }
                    });

                    const filteredProducts = standProducts.data.filter( (item) => item.id !== product.id);

                    cache.writeQuery({
                        query: GET_PRODUCTS_BY_STAND,
                        variables: {
                            id: product.stand.id
                        },
                        data: {
                            standProducts: {
                                data : [
                                    ...filteredProducts
                                ],
                                __typename: standProducts.__typename
                            }
                        },
                    });
                }
            , 500);

            toggleConfirmModal(false);
            action.close()


            successNotification({
                title: label,
                description: message,
            });

        }
    }).catch((error) => {
        errorNotification(error);
    });

};


const ProductDelete = ({
        product,
        modalText = "Are you sure you want to delete this product?",
        buttonText = "Delete product",
        action
    }) =>{

    const [visibleConfirmModal, toggleConfirmModal] = useState(false);
    const [removeProduct, { loading : removeLoading }] = useMutation(PRODUCT_DELETE);

    return (
        <>
            <Button
                type="default"
                onClick={ () => toggleConfirmModal(true) }
            >
                { buttonText }
            </Button>
            <Modal
                className="delete-confirm-modal"
                visible={ visibleConfirmModal }
                closeIcon={ <Icons.Cross /> }
                width={ 420 }
                centered
                onOk={
                    () => {

                        productDeleteAction({
                            product,
                            removeProduct,
                            toggleConfirmModal,
                            action
                        })
                    }
                }
                onCancel={() => toggleConfirmModal(false)}
                okText='Delete'
                okButtonProps={
                    {
                        loading : removeLoading,
                        type: "primary"
                    }
                }
                cancelText='Cancel'
                cancelButtonProps={
                    { type: "default" }
                }
            >
                <p>{ modalText }</p>
            </Modal>
        </>
    );
};

export default ProductDelete;