import React from "react";
import { Link } from "react-router-dom";

import { FavoriteButton } from "components/favorite";
import Icons from "components/icons";

import "./card-product-vertical.scss";


const CoverWrap = ({ imgWithLink, uriParams, children }) => {

    return(
        <>
            { !imgWithLink ?
                <div className="product-cover">{ children }</div> :
                <Link
                    className="product-cover"
                    to={{ ...uriParams }}
                >
                    { children }
                </Link>
            }
        </>
    );
}

const CardProductVertical = ({
         product,
         cardClass ="",
         uriParams,
         imgWithLink = false
    }) => {

    return(
        <div
            className={ `product-card-vertical ${cardClass}` }
            key={ product.id }
        >
            <CoverWrap
                uriParams={ uriParams }
                imgWithLink={ imgWithLink }
            >
                { product.cover ?
                    <img src={ product.cover } alt={ product.title } /> :
                    <Icons.NoImage />
                }
            </CoverWrap>
            <div className="product-description">
                <h5>{ product.title }</h5>
            </div>
            <div className="product-actions-wrap">
                <div className="product-actions">
                    <Link
                        to={{ ...uriParams }}
                        className="link-button second"
                    >View more</Link>
                    <div className="to-favorite">
                        <FavoriteButton
                            favorite={ product.my_favorite }
                            type={ "Product" }
                            id={ product.id }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardProductVertical;