import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from "@apollo/client";


import client from "graphql/client";

import App from 'components/app';
import Authentication from "components/authentication";
import Router from "components/routes";
import { ScrollToTop } from 'components/service';

import 'antd/lib/style/themes/default.less';
import 'antd/dist/antd.less';
import "scss/default.scss";


ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <Authentication>
                <ScrollToTop />
                <App />
            </Authentication>
        </Router>
    </ApolloProvider>,
    document.getElementById('root')
);
