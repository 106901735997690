import React from "react";

import { PackageList } from "components/package";
import { ContainerWrap } from "components/wraps";
import { useExhibitionEvent } from "components/use-hooks";
import { metaFields } from "utils";

const getPackages = (packages, packageOrder, currentPackage) =>{

    let packageList = [];

    for(let i = 0; i < packageOrder.length; i++){
        let pack = packages.find( (item) => {
            if(packageOrder[i] === item.id){
                return item;
            }

            return false;
        })

        if(pack){
            packageList.push(pack);
        }
    }

    if(currentPackage && packageOrder.indexOf( currentPackage.id ) === -1){
        packageList = [currentPackage, ...packageList];
    }

    return packageList;
}


const ShowroomPackageSubPage = ({ stand }) => {

    const ee = useExhibitionEvent();

    const { normalize, getValue } = metaFields;
    const meta = normalize(ee.meta_fields);

    let packages = getPackages(
            ee.packages,
            getValue(meta, "stand_packages"),
            stand.package
        );

    return (
        <ContainerWrap className="showroom-package-wrap">
            <div className="badge-page-label">
                You are looking at the  package details
            </div>
            { stand &&
                <>
                    <div className="packages-header">
                        <h2>Package details</h2>
                        <div className="current-package">
                            { stand.package ?
                                <>You are using <b>{ stand.package.title } package</b> </> :
                                <>Package not set for your showroom yet</>
                            }
                        </div>
                    </div>
                    <PackageList
                        packages={ packages }
                        currentId={ stand.package.id }
                        standId={ stand.id }
                    />
                </>
            }
        </ContainerWrap>
    );
};


export default ShowroomPackageSubPage;