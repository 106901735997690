import React from 'react';
import Icon from '@ant-design/icons';


const SoundSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M25,11.1l-8.8,7.7v0.1h-5.9c-0.9,0-1.6,0.7-1.6,1.6v9.1c0,0.9,0.7,1.6,1.6,1.6h5.9v0.1L25,39c1,0.9,2.6,0.2,2.6-1.2V12.3
		C27.6,10.9,26,10.2,25,11.1z"/>
        <path d="M31.5,18.8c-0.4-0.3-1.1-0.2-1.4,0.2c-0.3,0.4-0.2,1.1,0.2,1.4c1.6,1.2,2.5,3,2.5,4.9c0,1.8-0.8,3.5-2.1,4.7
		c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.5-0.1,0.7-0.2c1.8-1.6,2.8-3.8,2.8-6.2C34.8,22.7,33.6,20.4,31.5,18.8z"
        />
        <path d="M35,16.9c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4c1.9,1.9,2.9,4.3,2.9,7c0,2.5-0.9,4.9-2.7,6.7c-0.4,0.4-0.4,1,0,1.4
		c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c2.1-2.2,3.2-5.1,3.2-8.1C38.5,22.2,37.2,19.2,35,16.9z"/>
    </svg>
);

const PlaySvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M19.3,23.9v-5.5c0-0.9,1-1.5,1.8-1l4.7,2.7l4.7,2.7c0.8,0.5,0.8,1.6,0,2l-4.7,2.7l-4.7,2.7c-0.8,0.5-1.8-0.1-1.8-1V23.9z"
        />
        <path d="M24,41.5c-9.7,0-17.6-7.9-17.6-17.6c0-9.7,7.9-17.6,17.6-17.6s17.6,7.9,17.6,17.6C41.6,33.6,33.7,41.5,24,41.5z M24,8.3
			c-8.6,0-15.6,7-15.6,15.6s7,15.6,15.6,15.6s15.6-7,15.6-15.6S32.6,8.3,24,8.3z"/>
    </svg>
);

const PauseSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <g>
            <path d="M24,41.5c-9.7,0-17.6-7.9-17.6-17.6c0-9.7,7.9-17.6,17.6-17.6s17.6,7.9,17.6,17.6C41.6,33.6,33.7,41.5,24,41.5z M24,8.3
				c-8.6,0-15.6,7-15.6,15.6s7,15.6,15.6,15.6s15.6-7,15.6-15.6S32.6,8.3,24,8.3z"/>
        </g>
        <path d="M21.5,30.6H19c-0.7,0-1.2-0.5-1.2-1.2V18.9c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v10.5
			C22.7,30.1,22.2,30.6,21.5,30.6z"/>
        <path d="M29.2,30.6h-2.5c-0.7,0-1.2-0.5-1.2-1.2V18.9c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v10.5
			C30.4,30.1,29.9,30.6,29.2,30.6z"/>
    </svg>
);


const PlayerIcon = props => {

    if(props.type === "sound"){
        return <Icon component={ SoundSvg } />;
    }

    if(props.type === "play"){
        return <Icon component={ PlaySvg } />;
    }

    if(props.type === "pause"){
        return <Icon component={ PauseSvg } />;
    }

};


export default PlayerIcon;